<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down">
    <q-card style="width: 540px">
      <div class="q-pa-md">
        <q-card-section class="row items-center q-pb-lg">
          <div
            class="text-uppercase text-grey-8 q-mt-sm"
            style="font-size:13pt"
            v-html="$t('my_points.award.doc_pend_rescue_title')"
          />
          <q-space />
          <q-btn icon="close" color="red" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-none q-pb-none">
          <p
            class="text-justify"
            v-html="$t(label)"
          />
        </q-card-section>

        <q-card-section class="bg-grey-3 default-rounded">
          <q-list>
            <q-item v-for="(pend, i) in docs_pend" :key="i">
              <q-item-section avatar>
                <q-icon color="red" name="error" />
              </q-item-section>
              <q-item-section class="text-red">{{
                pend.description
              }}</q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-actions align="center" class="q-mt-md">
          <router-link class="btn" :to="{ name: 'account.personal-info' }">
            <q-btn
              flat
              v-close-popup
              color="white"
              icon="person"
              class="q-pa-md bg-black"
              :label="$t('my_points.award.doc_pend_rescue_access')"
            />
          </router-link>
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "docPendsComponent",

  props: {
    docs_pend: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.btn {
  text-decoration: none !important;
}
</style>
